import React from 'react'
import { Link } from 'gatsby'
import Helmet from "react-helmet";

const ContaMap = () => (
	<div className="white">
	<h1>Conta Map – Privacy Policy</h1>

	<p>Conta Map is the safe and reliable bridge to connect and amplify the victims voices of political and ideological violence. With Conta Map we have a powerful tool to fight against intolerance. #NoRoomForHate</p>

	<p>Effective date: January 08, 2020</p>

	<p>Barcelona Code School ("us", "we", or "our") operates the Conta Map mobile application (the "Service").</p>

	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

	<h2>Information Collection And Use</h2>

	<p>Conta Map mobil app requires to have access to the geolocation of the user to properly display the local map of hate crimes. The only user data we require is that said current geolocation. 
	All the data you enter while using the "Service" is used purely to properly display the local map around current user location. In no way this data is transfered, stored or shared with any other parties, devices or resources.</p>


	<h2>Contact Us</h2>
	<p>If you have any questions about this Privacy Policy, please contact us:</p>
	<ul>
	<li>By <a href="mailto:office@barcelonacodeschool.com">sending the email</a> or visiting <a href="/contacts/">contact page</a>.</li>
	
	</ul>                                


	</div>
	)

export default ContaMap
